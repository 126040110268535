import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import {
  DataType,
  GallerySectionData,
  GallerySectionItemData,
  ThemeData,
} from '../model';
import { DataHook, DataIndex } from '../types';
import { useGallerySectionItemData } from './use-gallery-section-item-data';
import { useThemeData } from './use-theme-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    itemsDataIndex: DataIndex<GallerySectionItemData>,
    themeDataIndex: DataIndex<ThemeData>,
  ): DataIndex<GallerySectionData> => {
    const list: GallerySectionData[] = graphqlResult.allContentfulGallerySection.nodes.map(
      (node): GallerySectionData => ({
        id: node.contentful_id,
        type: DataType.GallerySection,
        data: rejectNil({
          title: node.title,
          description: node.description,
          items: node.items?.map(
            item => itemsDataIndex.record[item.contentful_id],
          ),
          theme:
            node.theme?.contentful_id === undefined
              ? undefined
              : themeDataIndex.record[node.theme.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useGallerySectionData: DataHook<GallerySectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulGallerySection {
        nodes {
          contentful_id
          title
          items {
            contentful_id
          }
          # TODO when issue related to contentful schema are solved, uncomment
          # theme {
          #   contentful_id
          # }
          # TODO when issue related to contentful schema are solved, uncomment
          #   description
        }
      }
    }
  `);

  const index = makeIndex(
    graphqlResult,
    useGallerySectionItemData(),
    useThemeData(),
  );

  return id === undefined ? index : index.record[id];
};
