import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { CollapsibleListItemData, DataType } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (graphqlResult: any): DataIndex<CollapsibleListItemData> => {
    const list: CollapsibleListItemData[] = graphqlResult.allContentfulCollapsibleListItem.nodes.map(
      (node): CollapsibleListItemData => ({
        id: node.contentful_id,
        type: DataType.CollapsibleListItem,
        data: rejectNil({
          title: node.title,
          content: node.content,
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useCollapsibleListItemData: DataHook<CollapsibleListItemData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulCollapsibleListItem {
        nodes {
          contentful_id
          title
          content {
            json
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
