import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import {
  ButtonData,
  CustomSectionData,
  CustomSectionSubsectionsData,
  DataType,
  ThemeData,
} from '../model';
import {
  CombinedIndex,
  DataHook,
  DataIndex,
  RequiredDataHooks,
} from '../types';
import { useButtonData } from './use-button-data';
import { useCardGroupData } from './use-card-group-data';
import { useCollapsibleListData } from './use-collapsible-list-data';
import { useGridData } from './use-grid-data';
import { useGridListData } from './use-grid-list-data';
import { useMapData } from './use-map-data';
import { useNumbersListData } from './use-numbers-list-data';
import { useTestimonialsData } from './use-testimonials-data';
import { useThemeData } from './use-theme-data';
import { makeDataIndexFromList } from './utils';

const requiredDataHooks: RequiredDataHooks<CustomSectionSubsectionsData> = {
  [DataType.CardGroup]: useCardGroupData,
  [DataType.Grid]: useGridData,
  [DataType.GridList]: useGridListData,
  [DataType.Testimonials]: useTestimonialsData,
  [DataType.CollapsibleList]: useCollapsibleListData,
  [DataType.NumbersList]: useNumbersListData,
  [DataType.Map]: useMapData,
};

const makeIndex = once(
  (
    graphqlResult: any,
    subsectionsIndex: CombinedIndex<CustomSectionSubsectionsData>,
    themeDataIndex: DataIndex<ThemeData>,
    buttonDataIndex: DataIndex<ButtonData>,
  ): DataIndex<CustomSectionData> => {
    const list: CustomSectionData[] = graphqlResult.allContentfulCustomSection.nodes.map(
      (node): CustomSectionData => ({
        id: node.contentful_id,
        type: DataType.CustomSection,
        data: rejectNil({
          title: node.title,
          tempEnableGangstaLasers: node.tempEnableGangstaLasers,
          description: node.description,
          button:
            node.button?.contentful_id === undefined
              ? undefined
              : buttonDataIndex.record[node.button.contentful_id],
          subsections: node.subsections?.map(
            (subsection): CustomSectionSubsectionsData =>
              subsectionsIndex[subsection.contentful_id],
          ),
          theme:
            node.theme?.contentful_id === undefined
              ? undefined
              : themeDataIndex.record[node.theme.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useCustomSectionData: DataHook<CustomSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulCustomSection {
        nodes {
          contentful_id
          title
          tempEnableGangstaLasers
          description {
            json
          }
          button {
            contentful_id
          }
          theme {
            contentful_id
          }
          subsections {
            ... on ContentfulGridList {
              contentful_id
            }
            ... on ContentfulCardGroup {
              contentful_id
            }
            ... on ContentfulTestimonials {
              contentful_id
            }
            ... on ContentfulGrid {
              contentful_id
            }
            ... on ContentfulCollapsibleList {
              contentful_id
            }
            ... on ContentfulMap {
              contentful_id
            }
            ... on ContentfulNumbersList {
              contentful_id
            }
          }
        }
      }
    }
  `);

  const subsectionsIndex = Object.values(requiredDataHooks).reduce<
    CombinedIndex<CustomSectionSubsectionsData>
  >((acc, dataHook) => ({ ...acc, ...dataHook().record }), {});

  const index = makeIndex(
    graphqlResult,
    subsectionsIndex,
    useThemeData(),
    useButtonData(),
  );

  return id === undefined ? index : index.record[id];
};
