import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType } from '../model';
import { PageSeoData } from '../model/page-seo';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (graphqlResult: any): DataIndex<PageSeoData> => {
    const list: PageSeoData[] = graphqlResult.allContentfulPageSeo.nodes.map(
      (node): PageSeoData => ({
        id: node.contentful_id,
        type: DataType.PageSeo,
        data: rejectNil({
          path: node.path,
          title: node.title,
          description: node.description,
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const usePageSeoData: DataHook<PageSeoData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulPageSeo {
        nodes {
          contentful_id
          path
          title
          description
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
