import { graphql, useStaticQuery } from 'gatsby';

import { once } from '../../utils/fp';
import { rejectNil } from '../../utils/object';
import { CompareImageSectionItemData } from '../model';
import { DataType } from '../model/common';
import { ImageData } from '../model/image';
import { DataHook, DataIndex } from '../types';
import { useImageData } from './use-image-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    imageDataIndex: DataIndex<ImageData>,
  ): DataIndex<CompareImageSectionItemData> => {
    const list: CompareImageSectionItemData[] = graphqlResult.allContentfulCompareImageSectionItem.nodes.map(
      (node): CompareImageSectionItemData => ({
        id: node.contentful_id,
        type: DataType.CompareImageSectionItem,
        data: rejectNil({
          title: node.title,
          description: node.description,
          beforeImage:
            node.beforeImage?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.beforeImage.contentful_id],
          afterImage:
            node.afterImage?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.afterImage.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useCompareImageSectionItemData: DataHook<CompareImageSectionItemData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulCompareImageSectionItem {
        nodes {
          contentful_id
          beforeImage {
            contentful_id
          }
          afterImage {
            contentful_id
          }
          title
          description {
            json
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
