import { win } from '../fragments/window';

export const resolveBlogPostPath = (date: string, slug: string) =>
  `/blog/${date}/${slug}`;

export const formatBlogPostDate = (date: string) =>
  win?.Intl
    ? new Intl.DateTimeFormat(navigator.language, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(new Date(date))
    : date;
