import { Dictionary } from '../types/common';

export const classNameMap = (map: Dictionary<boolean>): string | undefined => {
  const result = Object.entries(map)
    .reduce<string[]>(
      (accumulator, [className, value]) =>
        value === true ? [...accumulator, className] : accumulator,
      [],
    )
    .join(' ');

  if (result.length === 0) {
    return undefined;
  }

  return result;
};

export const joinClassNames = (...parameters: unknown[]): string =>
  parameters.filter(parameter => typeof parameter === 'string').join(' ');
