import { graphql, useStaticQuery } from 'gatsby';

import { once, priceSorter, rejectNil } from '../../utils';
import {
  DataType,
  ImageData,
  ServiceType,
  ThemeData,
  TreatmentDetailsSectionData,
} from '../model';
import { DataHook, DataIndex } from '../types';
import { useImageData } from './use-image-data';
import { useThemeData } from './use-theme-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    imageDataIndex: DataIndex<ImageData>,
    themeDataIndex: DataIndex<ThemeData>,
  ): DataIndex<TreatmentDetailsSectionData> => {
    const list: TreatmentDetailsSectionData[] = graphqlResult.allContentfulTreatmentDetailsSection.nodes.map(
      (node): TreatmentDetailsSectionData => ({
        id: node.contentful_id,
        type: DataType.TreatmentDetailsSection,
        data: rejectNil({
          title: node.title,
          description: node.description,
          shortDescription: node.shortDescription,
          price: node.price,
          keypoints: node.keypoints,
          serviceId: node.serviceId,
          serviceType: ServiceType.Treatments,
          caption: node.caption,
          image:
            node.image?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.image.contentful_id],
          theme:
            node.theme?.contentful_id === undefined
              ? undefined
              : themeDataIndex.record[node.theme.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(
      list.sort((a, b) => priceSorter(a.data.price, b.data.price)),
    );
  },
);

export const useTreatmentDetailsSectionData: DataHook<TreatmentDetailsSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulTreatmentDetailsSection {
        nodes {
          contentful_id
          title
          description {
            json
          }
          price
          keypoints
          serviceId
          caption
          image {
            contentful_id
          }
          shortDescription {
            json
          }
          # TODO when issue related to contentful schema are solved, uncomment
          # theme {
          #   contentful_id
          # }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData(), useThemeData());

  return id === undefined ? index : index.record[id];
};
