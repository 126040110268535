import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import {
  ButtonData,
  DataType,
  ImageData,
  SalesSectionItemData,
} from '../model';
import { DataHook, DataIndex } from '../types';
import { useButtonData } from './use-button-data';
import { useImageData } from './use-image-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    buttonDataIndex: DataIndex<ButtonData>,
    imageDataIndex: DataIndex<ImageData>,
  ): DataIndex<SalesSectionItemData> => {
    const list: SalesSectionItemData[] = graphqlResult.allContentfulSalesSectionItem.nodes.map(
      (node): SalesSectionItemData => ({
        id: node.contentful_id,
        type: DataType.SalesSectionItem,
        data: rejectNil({
          title: node.title,
          subTitle: node.subTitle,
          description: node.description,
          button:
            node.button?.contentful_id === undefined
              ? undefined
              : buttonDataIndex.record[node.button.contentful_id],
          image:
            node.image?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.image.contentful_id],
          companyLogo:
            node.companyLogo?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.companyLogo.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useSalesSectionItemData: DataHook<SalesSectionItemData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulSalesSectionItem {
        nodes {
          contentful_id
          title
          subTitle
          description {
            json
          }
          button {
            contentful_id
          }
          image {
            contentful_id
          }
          # companyLogo {
          #   contentful_id
          # }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useButtonData(), useImageData());

  return id === undefined ? index : index.record[id];
};
