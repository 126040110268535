import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType, GallerySectionItemImageData, ImageData } from '../model';
import { DataHook, DataIndex } from '../types';
import { useImageData } from './use-image-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    imageDataIndex: DataIndex<ImageData>,
  ): DataIndex<GallerySectionItemImageData> => {
    const list: GallerySectionItemImageData[] = graphqlResult.allContentfulGallerySectionItemImage.nodes.map(
      (node): GallerySectionItemImageData => ({
        id: node.contentful_id,
        type: DataType.GallerySectionItemImage,
        data: rejectNil({
          title: node.title,
          description: node.description,
          image:
            node.image?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.image.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useGallerySectionItemImageData: DataHook<GallerySectionItemImageData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulGallerySectionItemImage {
        nodes {
          contentful_id
          title
          image {
            contentful_id
          }
          # TODO when issue related to contentful schema are solved, uncomment
          # description
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
