export enum StaticPage {
  Home = 'home',
  About = 'about',
  Trainings = 'trainings',
  Treatments = 'treatments',
  Prices = 'prices',
  Gallery = 'gallery',
  Blog = 'blog',
  Contact = 'contact',
  PrivacyPolicy = 'privacy-policy',
  Handbook = 'handbook',
  Laser = 'laser',
}

interface PageConfigurationBase<TType extends string> {
  template: string;
  type: TType;
}

export interface StaticPageConfiguration
  extends PageConfigurationBase<'static'> {
  page: StaticPage;
  contentfulId: string;
}

export type PagesConfiguration = StaticPageConfiguration[];

export interface PageDefinition {
  id: string;
  path: string;
}
