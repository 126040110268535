import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType, ImageData, SocialMediaItemData } from '../model';
import { DataHook, DataIndex } from '../types';
import { useImageData } from './use-image-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    imageDataIndex: DataIndex<ImageData>,
  ): DataIndex<SocialMediaItemData> => {
    const list: SocialMediaItemData[] = graphqlResult.allContentfulSocialMediaItem.nodes.map(
      (node): SocialMediaItemData => ({
        id: node.contentful_id,
        type: DataType.SocialMediaItem,
        data: rejectNil({
          href: node.href,
          icon:
            node.icon?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.icon.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useSocialMediaItemData: DataHook<SocialMediaItemData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulSocialMediaItem {
        nodes {
          contentful_id
          href
          icon {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
