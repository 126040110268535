import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType, TestimonialData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (graphqlResult: any): DataIndex<TestimonialData> => {
    const list: TestimonialData[] = graphqlResult.allContentfulTestimonial.nodes.map(
      (node): TestimonialData => ({
        id: node.contentful_id,
        type: DataType.Testimonial,
        data: rejectNil({
          author: node.testimonialAuthor,
          content: node.testimonialContent,
          rating: node.testimonialRating,
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useTestimonialData: DataHook<TestimonialData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulTestimonial {
        nodes {
          contentful_id
          testimonialRating
          testimonialAuthor
          testimonialContent {
            json
          }
        }
      }
    }
  `);
  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
