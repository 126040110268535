// eslint-disable-next-line @typescript-eslint/ban-types
export const rejectNil = <TData extends object>(data) =>
  Object.entries(data).reduce<TData>((acc, [key, val]) => {
    if (val === undefined || val === null) {
      return acc;
    }

    return { ...acc, [key]: val };
  }, {} as TData);

export const omit = (obj: any, keys: string[]) =>
  Object.fromEntries(
    Object.entries(obj ?? {}).filter(([key]) => !keys.includes(key)),
  );
