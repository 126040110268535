import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType, GridListItemData, ImageData } from '../model';
import { DataHook, DataIndex } from '../types';
import { useImageData } from './use-image-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    imageDataIndex: DataIndex<ImageData>,
  ): DataIndex<GridListItemData> => {
    const list: GridListItemData[] = graphqlResult.allContentfulGridListItem.nodes.map(
      (node): GridListItemData => ({
        id: node.contentful_id,
        type: DataType.GridListItem,
        data: rejectNil({
          description: node.description,
          title: node.title,
          image: imageDataIndex.record[node.image?.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useGridListItemData: DataHook<GridListItemData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulGridListItem {
        nodes {
          contentful_id
          description
          title
          image {
            contentful_id
          }
        }
      }
    }
  `);

  const imageDataIndex = useImageData();
  const index = makeIndex(graphqlResult, imageDataIndex);

  return id === undefined ? index : index.record[id];
};
