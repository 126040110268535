import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType, MapData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (graphqlResult: any): DataIndex<MapData> => {
    const list: MapData[] = graphqlResult.allContentfulMap.nodes.map(
      (node): MapData => ({
        id: node.contentful_id,
        type: DataType.Map,
        data: rejectNil({
          googleApiUrl: node.googleApiUrl,
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useMapData: DataHook<MapData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulMap {
        nodes {
          contentful_id
          googleApiUrl
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
