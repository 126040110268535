import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType, FormData, ThemeData } from '../model';
import { ContactSectionData } from '../model/contact-section';
import { DataHook, DataIndex } from '../types';
import { useFormData } from './use-form-data';
import { useThemeData } from './use-theme-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    formDataIndex: DataIndex<FormData>,
    themeDataIndex: DataIndex<ThemeData>,
  ): DataIndex<ContactSectionData> => {
    const list: ContactSectionData[] = graphqlResult.allContentfulContactSection.nodes.map(
      (node): ContactSectionData => ({
        id: node.contentful_id,
        type: DataType.ContactSection,
        data: rejectNil({
          title: node.title,
          form:
            node.form?.contentful_id === undefined
              ? undefined
              : formDataIndex.record[node.form.contentful_id],
          theme:
            node.theme?.contentful_id === undefined
              ? undefined
              : themeDataIndex.record[node.theme.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useContactSectionData: DataHook<ContactSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulContactSection {
        nodes {
          contentful_id
          title
          form {
            contentful_id
          }
          # TODO when issue related to contentful schema are solved, uncomment
          # theme {
          #   contentful_id
          # }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useFormData(), useThemeData());

  return id === undefined ? index : index.record[id];
};
