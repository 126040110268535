import { LayoutBreakpoint } from './types';

export const CLASS_PREFIX = 'layout';

export const MAX_COLUMNS_COUNT = 24;

// These values must be mirrored in the _config.scss file!
export const BREAKPOINTS: { [TBreakpoint in LayoutBreakpoint]: number } = {
  [LayoutBreakpoint.SM]: 0,
  [LayoutBreakpoint.XS]: 576,
  [LayoutBreakpoint.MD]: 768,
  [LayoutBreakpoint.LG]: 992,
  [LayoutBreakpoint.XL]: 1200,
  [LayoutBreakpoint.XXL]: 1600,
};

export const BREAKPOINTS_HIERARCHY = Object.entries(BREAKPOINTS)
  .sort(([, a], [, b]) => a - b)
  .map(([breakpoint]) => breakpoint as LayoutBreakpoint);

export const BREAKPOINTS_HIERARCHY_REVERSE: LayoutBreakpoint[] = BREAKPOINTS_HIERARCHY.slice().reverse();

export const DEFAULT_BREAKPOINT = BREAKPOINTS_HIERARCHY[0];
