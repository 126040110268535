export enum DataType {
  AboutSection = 'about-section',
  AboutSectionItem = 'about-section-item',
  BlogPostPage = 'blog-post-page',
  Button = 'button',
  Card = 'card',
  CardGroup = 'card-group',
  CollapsibleList = 'collapsible-list',
  CollapsibleListItem = 'collapsible-list-item',
  CompareImageSection = 'compare-image-section',
  CompareImageSectionItem = 'compare-image-section-item',
  Config = 'config',
  ContactSection = 'contact-section',
  CtaSection = 'cta-section',
  CustomSection = 'custom-section',
  Form = 'form',
  FormField = 'form-field',
  GallerySection = 'gallery-section',
  GallerySectionItem = 'gallery-section-item',
  GallerySectionItemImage = 'gallery-section-item-image',
  Grid = 'grid',
  GridList = 'grid-list',
  GridListItem = 'grid-list-item',
  HeroSection = 'hero-section',
  Image = 'image',
  InterleavingImagesSection = 'interleaving-images-section',
  InterleavingImagesSectionItem = 'interleaving-images-section-item',
  Map = 'map',
  Modal = 'modal',
  NumbersList = 'numbers-list',
  NumbersListItem = 'numbers-list-item',
  Page = 'page',
  PageSeo = 'page-seo',
  PricesSection = 'prices-section',
  PricesSectionItem = 'prices-section-item',
  PricesSectionItemPrice = 'prices-section-item-price',
  SalesSection = 'sales-section',
  SalesSectionItem = 'sales-section-item',
  ServiceSection = 'service-section',
  SocialMedia = 'social-media',
  SocialMediaItem = 'social-media-item',
  Testimonial = 'testimonial',
  Testimonials = 'testimonials',
  Theme = 'theme',
  TrainingDetailsSection = 'training-details-section',
  TrainingPage = 'training-page',
  TreatmentDetailsSection = 'treatment-details-section',
  TreatmentPage = 'treatment-page',
}

export type DataEntryBase<TType extends DataType, TData extends unknown> = {
  id: string;
  type: TType;
  data: TData;
};

export enum ServiceId {
  // Trainings
  Beginner = 'beginner',
  Intermediate = 'intermediate',
  Advanced = 'advanced',
  //Treatments
  PmuEyes = 'pmuEyes',
  PmuBrows = 'pmuBrows',
  PmuLips = 'pmuLips',
  PmuModifications = 'pmuModifications',
  Other = 'other',
  Laser = 'laser',
  BioTatoo = 'bioTatoo',
  Lashes = 'lashes',
}

export enum ServiceType {
  Trainings = 'trainings',
  Treatments = 'treatments',
}
