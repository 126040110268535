import { graphql, useStaticQuery } from 'gatsby';

import { once } from '../../utils/fp';
import { rejectNil } from '../../utils/object';
import { ButtonData, DataType, ImageData, ThemeData } from '../model';
import { HeroSectionData } from '../model/hero-section';
import { DataHook, DataIndex } from '../types';
import { useButtonData } from './use-button-data';
import { useImageData } from './use-image-data';
import { useThemeData } from './use-theme-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    buttonDataIndex: DataIndex<ButtonData>,
    imageDataIndex: DataIndex<ImageData>,
    themeDataIndex: DataIndex<ThemeData>,
  ): DataIndex<HeroSectionData> => {
    const list: HeroSectionData[] = graphqlResult.allContentfulHeroSection.nodes.map(
      (node): HeroSectionData => ({
        id: node.contentful_id,
        type: DataType.HeroSection,
        data: rejectNil({
          title: node.title,
          subTitle: node.subTitle,
          description: node.description,
          fullHeight: node.fullHeight,
          goBackButton:
            node.goBackButton?.contentful_id === undefined
              ? undefined
              : buttonDataIndex.record[node.goBackButton.contentful_id],
          fitInImage:
            node.fitInImage?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.fitInImage.contentful_id],
          fullFitInImage:
            node.fullFitInImage?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.fullFitInImage.contentful_id],
          theme:
            node.theme?.contentful_id === undefined
              ? undefined
              : themeDataIndex.record[node.theme.contentful_id],
          buttons: node.buttons?.map(
            item => buttonDataIndex.record[item.contentful_id],
          ),
          fullFitInVideo: node.fullFitInVideo
            ? {
                title: node.fullFitInVideo?.title,
                url: node.fullFitInVideo?.file?.url,
              }
            : undefined,
          fitInVideo: node.fitInVideo
            ? { title: node.fitInVideo?.title, url: node.fitInVideo?.file?.url }
            : undefined,
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useHeroSectionData: DataHook<HeroSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulHeroSection {
        nodes {
          contentful_id
          title
          subTitle
          description
          fullHeight
          fitInImage {
            contentful_id
          }
          fullFitInImage {
            contentful_id
          }
          goBackButton {
            contentful_id
          }
          buttons {
            contentful_id
          }
          fitInVideo {
            title
            file {
              url
            }
          }
          fullFitInVideo {
            title
            file {
              url
            }
          }
          # TODO when issue related to contentful schema are solved, uncomment
          # theme {
          #   contentful_id
          # }
        }
      }
    }
  `);

  const index = makeIndex(
    graphqlResult,
    useButtonData(),
    useImageData(),
    useThemeData(),
  );

  return id === undefined ? index : index.record[id];
};
