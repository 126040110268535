export enum LayoutComponent {
  Box = 'box',
  Column = 'column',
  Container = 'container',
  Row = 'row',
  Section = 'section',
}

export enum LayoutBreakpoint {
  SM = 'sm',
  XS = 'xs',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
}

export type BreakPointPropertyMap<T = any> = {
  [TBreakPoint in LayoutBreakpoint]?: T;
};

export type BreakpointProperty<T = any> = T | BreakPointPropertyMap<T>;
