import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { ButtonData, DataType, ImageData } from '../model';
import { DataHook, DataIndex } from '../types';
import { useImageData } from './use-image-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    imageDataIndex: DataIndex<ImageData>,
  ): DataIndex<ButtonData> => {
    const list: ButtonData[] = graphqlResult.allContentfulButton.nodes.map(
      (node): ButtonData => ({
        id: node.contentful_id,
        type: DataType.Button,
        data: rejectNil({
          label: node.label,
          link: node.link,
          href: node.href,
          type: node.type,
          modalId: node.modal?.contentful_id,
          startIcon:
            node.startIcon?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.startIcon.contentful_id],
          endIcon:
            node.endIcon?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.endIcon.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useButtonData: DataHook<ButtonData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulButton {
        nodes {
          contentful_id
          label
          link
          type
          modal {
            contentful_id
          }
          startIcon {
            contentful_id
          }
          # TODO when issue related to contentful schema are solved, uncomment
          # href
          # endIcon {
          #   contentful_id
          # }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
