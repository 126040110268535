import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { ButtonData, DataType, FormData, FormFieldData } from '../model';
import { DataHook, DataIndex } from '../types';
import { useButtonData } from './use-button-data';
import { useFormFieldData } from './use-form-field-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    itemsDataIndex: DataIndex<FormFieldData>,
    buttonDataIndex: DataIndex<ButtonData>,
  ): DataIndex<FormData> => {
    const list: FormData[] = graphqlResult.allContentfulForm.nodes.map(
      (node): FormData => ({
        id: node.contentful_id,
        type: DataType.Form,
        data: rejectNil({
          title: node.title,
          successMessage: node.successMessage,
          errorMessage: node.errorMessage,
          actionUrl: node.actionUrl,
          fields: node.formFields.map(
            item => itemsDataIndex.record[item.contentful_id],
          ),
          submitButton:
            node.submitButton?.contentful_id === undefined
              ? undefined
              : buttonDataIndex.record[node.submitButton.contentful_id],
        }) as any,
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useFormData: DataHook<FormData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulForm {
        nodes {
          contentful_id
          actionUrl
          errorMessage
          successMessage
          # TODO when issue related to contentful schema are solved, uncomment
          # title
          formFields {
            contentful_id
          }
          submitButton {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useFormFieldData(), useButtonData());

  return id === undefined ? index : index.record[id];
};
