import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import {
  DataType,
  PageSectionsData,
  PageSeoData,
  ServiceType,
  ThemeData,
  TrainingPageData,
} from '../model';
import {
  CombinedIndex,
  DataHook,
  DataIndex,
  RequiredDataHooks,
} from '../types';
import { useCtaSectionData } from './use-cta-section-data';
import { useCustomSectionData } from './use-custom-section-data';
import { useHeroSectionData } from './use-hero-section-data';
import { usePageSeoData } from './use-page-seo-data';
import { useThemeData } from './use-theme-data';
import { useTrainingDetailsSectionData } from './use-training-details-section-data';
import { makeDataIndexFromList } from './utils';

const requiredDataHooks: RequiredDataHooks<PageSectionsData> = {
  [DataType.CtaSection]: useCtaSectionData,
  [DataType.CustomSection]: useCustomSectionData,
  [DataType.TrainingDetailsSection]: useTrainingDetailsSectionData,
  [DataType.HeroSection]: useHeroSectionData,
  // TODO when issue related to contentful schema are solved, uncomment
} as RequiredDataHooks<PageSectionsData>;

const makeIndex = once(
  (
    graphqlResult: any,
    pageSeoDataIndex: DataIndex<PageSeoData>,
    sectionsDataIndex: CombinedIndex<PageSectionsData>,
    themeDataIndex: DataIndex<ThemeData>,
  ): DataIndex<TrainingPageData> => {
    const list: TrainingPageData[] = graphqlResult.allContentfulTrainingPage.nodes.map(
      (node): TrainingPageData => ({
        id: node.contentful_id,
        type: DataType.TrainingPage,
        data: rejectNil({
          navigationTitle: node.navigationTitle,
          serviceType: ServiceType.Trainings,
          navigationTheme:
            node.navigationTheme?.contentful_id === undefined
              ? undefined
              : themeDataIndex.record[node.navigationTheme.contentful_id],
          seo: pageSeoDataIndex.record[node.seo.contentful_id],
          serviceId: node.serviceId,
          sections:
            node.sections?.map(
              section => sectionsDataIndex[section.contentful_id],
            ) ?? [],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useTrainingPageData: DataHook<TrainingPageData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulTrainingPage {
        nodes {
          contentful_id
          navigationTitle
          navigationTheme {
            contentful_id
          }
          serviceId
          seo {
            contentful_id
          }
          sections {
            ... on ContentfulCustomSection {
              contentful_id
            }
            ... on ContentfulCtaSection {
              contentful_id
            }
            ... on ContentfulHeroSection {
              contentful_id
            }
            ... on ContentfulTrainingDetailsSection {
              contentful_id
            }
          }
        }
      }
    }
  `);

  const pageSeoDataIndex = usePageSeoData();
  const sectionsDataIndex = Object.values(requiredDataHooks).reduce<
    CombinedIndex<PageSectionsData>
  >((acc, dataHook) => ({ ...acc, ...dataHook().record }), {});

  const index = makeIndex(
    graphqlResult,
    pageSeoDataIndex,
    sectionsDataIndex,
    useThemeData(),
  );

  return id === undefined ? index : index.record[id];
};
