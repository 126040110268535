import { LayoutBreakpoint } from '../types';
import useScreens from './useScreens';

const useBreakpoint = (breakpoint: LayoutBreakpoint): boolean => {
  const [, screensSet] = useScreens();

  return screensSet.has(breakpoint);
};

export default useBreakpoint;
