import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { ButtonData, CardData, DataType } from '../model';
import { DataHook, DataIndex } from '../types';
import { useButtonData } from './use-button-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    buttonDataIndex: DataIndex<ButtonData>,
  ): DataIndex<CardData> => {
    const list: CardData[] = graphqlResult.allContentfulCardGroupItem.nodes.map(
      (node): CardData => ({
        id: node.contentful_id,
        type: DataType.Card,
        data: rejectNil({
          title: node.title,
          description: node.description,
          type: node.type,
          button:
            node.button?.contentful_id === undefined
              ? undefined
              : buttonDataIndex.record[node.button.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useCardData: DataHook<CardData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulCardGroupItem {
        nodes {
          contentful_id
          description
          type
          title
          button {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useButtonData());

  return id === undefined ? index : index.record[id];
};
