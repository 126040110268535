import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType, SocialMediaData, SocialMediaItemData } from '../model';
import { DataHook, DataIndex } from '../types';
import { useSocialMediaItemData } from './use-social-media-item-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    itemsDataIndex: DataIndex<SocialMediaItemData>,
  ): DataIndex<SocialMediaData> => {
    const list: SocialMediaData[] = graphqlResult.allContentfulSocialMedia.nodes.map(
      (node): SocialMediaData => ({
        id: node.contentful_id,
        type: DataType.SocialMedia,
        data: rejectNil({
          items: node.items?.map(
            item => itemsDataIndex.record[item.contentful_id],
          ),
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useSocialMediaData: DataHook<SocialMediaData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulSocialMedia {
        nodes {
          contentful_id
          items {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useSocialMediaItemData());

  return id === undefined ? index : index.record[id];
};
