import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType, GridListData, GridListItemData } from '../model';
import { DataHook, DataIndex } from '../types';
import { useGridListItemData } from './use-grid-list-item-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    itemsDataIndex: DataIndex<GridListItemData>,
  ): DataIndex<GridListData> => {
    const list: GridListData[] = graphqlResult.allContentfulGridList.nodes.map(
      (node): GridListData => ({
        id: node.contentful_id,
        type: DataType.GridList,
        data: rejectNil({
          title: node.title,
          columns: node.columns,
          items: node.items.map(
            item => itemsDataIndex.record[item.contentful_id],
          ),
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useGridListData: DataHook<GridListData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulGridList {
        nodes {
          contentful_id
          title
          columns
          items {
            contentful_id
          }
        }
      }
    }
  `);
  const itemsDataIndex = useGridListItemData();
  const index = makeIndex(graphqlResult, itemsDataIndex);

  return id === undefined ? index : index.record[id];
};
