import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import {
  CompareImageSectionData,
  CompareImageSectionItemData,
  DataType,
  ThemeData,
} from '../model';
import { DataHook, DataIndex } from '../types';
import { useCompareImageSectionItemData } from './use-compare-image-section-item-data';
import { useThemeData } from './use-theme-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    itemsDataIndex: DataIndex<CompareImageSectionItemData>,
    themeDataIndex: DataIndex<ThemeData>,
  ): DataIndex<CompareImageSectionData> => {
    const list: CompareImageSectionData[] = graphqlResult.allContentfulCompareImageSection.nodes.map(
      (node): CompareImageSectionData => ({
        id: node.contentful_id,
        type: DataType.CompareImageSection,
        data: rejectNil({
          beforeButtonLabel: node.beforeButtonLabel,
          afterButtonLabel: node.afterButtonLabel,
          items: node.items.map(
            item => itemsDataIndex.record[item.contentful_id],
          ),
          theme:
            node.theme?.contentful_id === undefined
              ? undefined
              : themeDataIndex.record[node.theme.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useCompareImageSectionData: DataHook<CompareImageSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulCompareImageSection {
        nodes {
          contentful_id
          # TODO when issue related to contentful schema are solved, uncomment
          # beforeButtonLabel
          # afterButtonLabel
          items {
            contentful_id
          }
          # TODO when issue related to contentful schema are solved, uncomment
          # theme {
          #   contentful_id
          # }
        }
      }
    }
  `);

  const index = makeIndex(
    graphqlResult,
    useCompareImageSectionItemData(),
    useThemeData(),
  );

  return id === undefined ? index : index.record[id];
};
