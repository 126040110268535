import { graphql, useStaticQuery } from 'gatsby';

import { once } from '../../utils/fp';
import { rejectNil } from '../../utils/object';
import { DataType } from '../model/common';
import { FormFieldData } from '../model/form-field';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (graphqlResult: any): DataIndex<FormFieldData> => {
    const list: FormFieldData[] = graphqlResult.allContentfulFormField.nodes.map(
      (node): FormFieldData => ({
        id: node.contentful_id,
        type: DataType.FormField,
        data: rejectNil({
          label: node.label,
          name: node.name,
          placeholder: node.placeholder,
          required: node.required,
          type: node.type,
          validationMessage: node.validationMessage,
          viewOnly: node.viewOnly,
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useFormFieldData: DataHook<FormFieldData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulFormField {
        nodes {
          contentful_id
          label
          name
          required
          type
          validationMessage
          # TODO when issue related to contentful schema are solved, uncomment
          # placeholder
          # viewOnly
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
