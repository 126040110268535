import { documentToReactComponents as originalParser } from '@contentful/rich-text-react-renderer';

/**
 * Options for the parser.
 * https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
 */
const options = {};

export const contentfulJSONToReactComponents = (json: any) =>
  originalParser(json, options);
