import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType, NumbersListItemData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (graphqlResult: any): DataIndex<NumbersListItemData> => {
    const list: NumbersListItemData[] = graphqlResult.allContentfulNumbersListItem.nodes.map(
      (node): NumbersListItemData => ({
        id: node.contentful_id,
        type: DataType.NumbersListItem,
        data: rejectNil({
          count: node.count,
          unit: node.unit,
          description: node.description,
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useNumbersListItemData: DataHook<NumbersListItemData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulNumbersListItem {
        nodes {
          contentful_id
          count
          unit
          description
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
