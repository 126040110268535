import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import {
  DataType,
  PageData,
  PageSectionsData,
  PageSeoData,
  ThemeData,
} from '../model';
import {
  CombinedIndex,
  DataHook,
  DataIndex,
  RequiredDataHooks,
} from '../types';
import { useAboutSectionData } from './use-about-section-data';
import { useCompareImageSectionData } from './use-compare-image-section-data';
import { useContactSectionData } from './use-contact-section-data';
import { useCtaSectionData } from './use-cta-section-data';
import { useCustomSectionData } from './use-custom-section-data';
import { useGallerySectionData } from './use-gallery-section-data';
import { useHeroSectionData } from './use-hero-section-data';
import { useInterleavingImagesSectionData } from './use-interleaving-images-section-data';
import { usePageSeoData } from './use-page-seo-data';
import { usePricesSectionData } from './use-prices-section-data';
import { useSalesSectionData } from './use-sales-section-data';
import { useServiceSectionData } from './use-service-section-data';
import { useThemeData } from './use-theme-data';
import { makeDataIndexFromList } from './utils';

const requiredDataHooks: RequiredDataHooks<PageSectionsData> = {
  [DataType.CtaSection]: useCtaSectionData,
  [DataType.CustomSection]: useCustomSectionData,
  [DataType.ContactSection]: useContactSectionData,
  [DataType.HeroSection]: useHeroSectionData,
  [DataType.AboutSection]: useAboutSectionData,
  [DataType.CompareImageSection]: useCompareImageSectionData,
  [DataType.GallerySection]: useGallerySectionData,
  [DataType.PricesSection]: usePricesSectionData,
  [DataType.SalesSection]: useSalesSectionData,
  [DataType.ServiceSection]: useServiceSectionData,
  [DataType.InterleavingImagesSection]: useInterleavingImagesSectionData,
  // [DataType.TrainingDetailsSection]: useTrainingDetailsSectionData,
  // [DataType.TreatmentDetailsSection]: useTreatmentDetailsSectionData,
  // TODO when issue related to contentful schema are solved, uncomment
} as RequiredDataHooks<PageSectionsData>;

const makeIndex = once(
  (
    graphqlResult: any,
    pageSeoDataIndex: DataIndex<PageSeoData>,
    sectionsDataIndex: CombinedIndex<PageSectionsData>,
    themeDataIndex: DataIndex<ThemeData>,
  ): DataIndex<PageData> => {
    const list: PageData[] = graphqlResult.allContentfulPage.nodes.map(
      (node): PageData => ({
        id: node.contentful_id,
        type: DataType.Page,
        data: rejectNil({
          navigationTitle: node.navigationTitle,
          navigationTheme:
            node.navigationTheme?.contentful_id === undefined
              ? undefined
              : themeDataIndex.record[node.navigationTheme.contentful_id],
          seo: pageSeoDataIndex.record[node.seo.contentful_id],
          sections:
            node.sections?.map(
              section => sectionsDataIndex[section.contentful_id],
            ) ?? [],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const usePageData: DataHook<PageData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulPage {
        nodes {
          contentful_id
          navigationTitle
          navigationTheme {
            contentful_id
          }
          seo {
            contentful_id
          }
          sections {
            ... on ContentfulCustomSection {
              contentful_id
            }
            ... on ContentfulCtaSection {
              contentful_id
            }
            ... on ContentfulHeroSection {
              contentful_id
            }
            ... on ContentfulContactSection {
              contentful_id
            }
            ... on ContentfulAboutSection {
              contentful_id
            }
            ... on ContentfulCompareImageSection {
              contentful_id
            }
            ... on ContentfulGallerySection {
              contentful_id
            }
            ... on ContentfulPricesSection {
              contentful_id
            }
            ... on ContentfulSalesSection {
              contentful_id
            }
            ... on ContentfulServiceSection {
              contentful_id
            }
            # ... on ContentfulInterleavingImagesSection {
            #   contentful_id
            # }
            # ... on ContentfulTrainingDetailsSection {
            #   contentful_id
            # }
            # ... on ContentfulTreatmentDetailsSection {
            #   contentful_id
            # }
          }
        }
      }
    }
  `);

  const pageSeoDataIndex = usePageSeoData();
  const sectionsDataIndex = Object.values(requiredDataHooks).reduce<
    CombinedIndex<PageSectionsData>
  >((acc, dataHook) => ({ ...acc, ...dataHook()?.record }), {});

  const index = makeIndex(
    graphqlResult,
    pageSeoDataIndex,
    sectionsDataIndex,
    useThemeData(),
  );

  return id === undefined ? index : index.record[id];
};
