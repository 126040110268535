import {
  ServiceId,
  ServiceType,
  useTrainingPageData,
  useTreatmentPageData,
} from '../data';

export interface ServicePagesPartial {
  navigationTitle: string;
  path: string;
  serviceId: string;
  serviceType: string;
}

export const useServicePagesPartial = (): {
  [key in ServiceType]?: { [key in ServiceId]: ServicePagesPartial[] };
} => {
  const trainingPages = useTrainingPageData();
  const treatmentPages = useTreatmentPageData();

  return [
    ...(trainingPages?.list ?? []),
    ...(treatmentPages?.list ?? []),
  ].reduce(
    (acc, page) => ({
      ...acc,
      [page.data.serviceType]: {
        ...(acc?.[page.data.serviceType] ?? {}),
        [page.data.serviceId]: [
          ...(acc?.[page.data.serviceType]?.[page.data.serviceId] ?? []),
          {
            navigationTitle: page.data.navigationTitle,
            path: page.data.seo.data.path,
            serviceId: page.data.serviceId,
            serviceType: page.data.serviceType,
          },
        ],
      },
    }),
    {},
  );
};
