import { DataEntryBase, DataType } from './common';

export enum FormFieldType {
  Email = 'email',
  Number = 'number',
  Text = 'text',
  Textarea = 'textarea',
  PhoneNumber = 'phoneNumber',
}

export type FormFieldData = DataEntryBase<
  DataType.FormField,
  {
    helpMessage?: string;
    label?: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    type: FormFieldType;
    validationMessage?: string;
    viewOnly?: boolean;
  }
>;
