import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import {
  DataType,
  SalesSectionData,
  SalesSectionItemData,
  ThemeData,
} from '../model';
import { DataHook, DataIndex } from '../types';
import { useSalesSectionItemData } from './use-sales-section-item-data';
import { useThemeData } from './use-theme-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    itemsDataIndex: DataIndex<SalesSectionItemData>,
    themeDataIndex: DataIndex<ThemeData>,
  ): DataIndex<SalesSectionData> => {
    const list: SalesSectionData[] = graphqlResult.allContentfulSalesSection.nodes.map(
      (node): SalesSectionData => ({
        id: node.contentful_id,
        type: DataType.SalesSection,
        data: rejectNil({
          items: node.items?.map(
            item => itemsDataIndex.record[item.contentful_id],
          ),
          theme:
            node.theme?.contentful_id === undefined
              ? undefined
              : themeDataIndex.record[node.theme.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useSalesSectionData: DataHook<SalesSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulSalesSection {
        nodes {
          contentful_id
          items {
            contentful_id
          }
          # TODO when issue related to contentful schema are solved, uncomment
          # theme {
          #   contentful_id
          # }
        }
      }
    }
  `);

  const index = makeIndex(
    graphqlResult,
    useSalesSectionItemData(),
    useThemeData(),
  );

  return id === undefined ? index : index.record[id];
};
