import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType, GridData, GridItemData } from '../model';
import {
  CombinedIndex,
  DataHook,
  DataIndex,
  RequiredDataHooks,
} from '../types';
import { useImageData } from './use-image-data';
import { makeDataIndexFromList } from './utils';

const requiredDataHooks: RequiredDataHooks<GridItemData> = {
  [DataType.Image]: useImageData,
};

const makeIndex = once(
  (
    graphqlResult: any,
    itemsDataIndex: CombinedIndex<GridItemData>,
  ): DataIndex<GridData> => {
    const list: GridData[] = graphqlResult.allContentfulGrid.nodes.map(
      (node): GridData => ({
        id: node.contentful_id,
        type: DataType.Grid,
        data: rejectNil({
          columns: node.columns,
          items: node.items.map(item => itemsDataIndex[item.contentful_id]),
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useGridData: DataHook<GridData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulGrid {
        nodes {
          contentful_id
          columns
          items {
            ... on ContentfulImage {
              contentful_id
            }
          }
        }
      }
    }
  `);

  const sectionsDataIndex = Object.values(requiredDataHooks).reduce<
    CombinedIndex<GridItemData>
  >((acc, dataHook) => ({ ...acc, ...dataHook().record }), {});

  const index = makeIndex(graphqlResult, sectionsDataIndex);

  return id === undefined ? index : index.record[id];
};
