import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import {
  AboutSectionData,
  AboutSectionItemData,
  DataType,
  ImageData,
  ThemeData,
} from '../model';
import { DataHook, DataIndex } from '../types';
import { useAboutSectionItemData } from './use-about-section-item-data';
import { useImageData } from './use-image-data';
import { useThemeData } from './use-theme-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    itemsDataIndex: DataIndex<AboutSectionItemData>,
    imageDataIndex: DataIndex<ImageData>,
    themeDataIndex: DataIndex<ThemeData>,
  ): DataIndex<AboutSectionData> => {
    const list: AboutSectionData[] = graphqlResult.allContentfulAboutSection.nodes.map(
      (node): AboutSectionData => ({
        id: node.contentful_id,
        type: DataType.AboutSection,
        data: rejectNil({
          title: node.title,
          description: node.description,
          image:
            node.image?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.image.contentful_id],
          theme:
            node.theme?.contentful_id === undefined
              ? undefined
              : themeDataIndex.record[node.theme.contentful_id],
          items: node.items.map(
            item => itemsDataIndex.record[item.contentful_id],
          ),
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useAboutSectionData: DataHook<AboutSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulAboutSection {
        nodes {
          contentful_id
          image {
            contentful_id
          }
          description {
            json
          }
          title
          items {
            contentful_id
          }
          # TODO when issue related to contentful schema are solved, uncomment
          # theme {
          #   contentful_id
          # }
        }
      }
    }
  `);

  const index = makeIndex(
    graphqlResult,
    useAboutSectionItemData(),
    useImageData(),
    useThemeData(),
  );

  return id === undefined ? index : index.record[id];
};
