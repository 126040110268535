import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import {
  DataType,
  ImageData,
  InterleavingImagesSectionItemData,
} from '../model';
import { DataHook, DataIndex } from '../types';
import { useImageData } from './use-image-data';
import { makeDataIndexFromList } from './utils';

// eslint-disable-next-line
const makeIndex = once(
  (
    graphqlResult: any,
    imageDataIndex: DataIndex<ImageData>,
  ): DataIndex<InterleavingImagesSectionItemData> => {
    const list: InterleavingImagesSectionItemData[] = graphqlResult.allContentfulInterleavingImagesSectionItem.nodes.map(
      (node): InterleavingImagesSectionItemData => ({
        id: node.contentful_id,
        type: DataType.InterleavingImagesSectionItem,
        data: rejectNil({
          description: node.description,
          image: imageDataIndex.record[node.image.contentful_id],
          title: node.title,
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useInterleavingImagesSectionItemData: DataHook<InterleavingImagesSectionItemData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulInterleavingImagesSectionItem {
        nodes {
          contentful_id
          image {
            contentful_id
          }
          title
          description {
            json
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
