import './styles/box.scss';

import React, { CSSProperties } from 'react';

import { joinClassNames } from '../../utils/tsx';
import useScreens from './hooks/useScreens';
import { BreakpointProperty, LayoutComponent } from './types';
import {
  makeClassName,
  makeClassNamesForBreakpoints,
  makeHiddenClassNameForBreakpoint,
  resolveBreakpointPropertyValue,
} from './utils';

export type BoxJustify =
  | 'start'
  | 'center'
  | 'end'
  | 'space-between'
  | 'space-around';

export type BoxAlign = 'top' | 'middle' | 'bottom';

export type BoxDirection = 'row' | 'column';

export interface BoxProps {
  className?: string;
  style?: BreakpointProperty<CSSProperties>;
  /** Whether the box should be hidden (equivalent of CSS "display: none") */
  hidden?: BreakpointProperty<boolean>;
  align?: BreakpointProperty<BoxAlign>;
  justify?: BreakpointProperty<BoxJustify>;
  direction?: BreakpointProperty<BoxDirection>;
  /** Whether the box should fill the height of it's container */
  fullHeight?: BreakpointProperty<boolean>;
}

const Box: React.FC<BoxProps> = ({
  align,
  children,
  className,
  direction,
  hidden,
  justify,
  fullHeight,
  style,
}) => {
  const [screens] = useScreens();
  const breakpoint = screens?.[0];
  const breakpointStyle = resolveBreakpointPropertyValue(breakpoint, style);

  return (
    <div
      className={joinClassNames(
        makeClassName(LayoutComponent.Box),
        ...makeClassNamesForBreakpoints(
          LayoutComponent.Box,
          'justify',
          justify,
        ),
        ...makeClassNamesForBreakpoints(LayoutComponent.Box, 'align', align),
        ...makeClassNamesForBreakpoints(
          LayoutComponent.Box,
          'direction',
          direction,
        ),
        ...makeClassNamesForBreakpoints(
          LayoutComponent.Box,
          'full-height',
          fullHeight,
        ),
        makeHiddenClassNameForBreakpoint(
          LayoutComponent.Box,
          hidden,
          breakpoint,
        ),
        className,
      )}
      style={breakpointStyle}
    >
      {children}
    </div>
  );
};

export default Box;
