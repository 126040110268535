import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType, ServiceSectionData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (graphqlResult: any): DataIndex<ServiceSectionData> => {
    const list: ServiceSectionData[] = graphqlResult.allContentfulServiceSection.nodes.map(
      (node): ServiceSectionData => ({
        id: node.contentful_id,
        type: DataType.ServiceSection,
        data: rejectNil({
          title: node.title,
          description: node.description,
          serviceType: node.serviceType,
          serviceId: node.serviceId,
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useServiceSectionData: DataHook<ServiceSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulServiceSection {
        nodes {
          contentful_id
          title
          description {
            json
          }
          serviceType
          serviceId
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
