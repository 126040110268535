import { joinDefined } from '../../utils/array';
import {
  BREAKPOINTS_HIERARCHY,
  BREAKPOINTS_HIERARCHY_REVERSE,
  CLASS_PREFIX,
  DEFAULT_BREAKPOINT,
} from './config';
import {
  BreakpointProperty,
  BreakPointPropertyMap,
  LayoutBreakpoint,
  LayoutComponent,
} from './types';

export const makeClassName = (
  suffix: string,
  component?: LayoutComponent,
): string => joinDefined('-', [CLASS_PREFIX, component, suffix]);

export const resolveBreakpointPropertyMap = <TValue>(
  breakpoints: BreakpointProperty<TValue>,
): BreakPointPropertyMap<TValue> =>
  typeof breakpoints === 'number' ||
  typeof breakpoints === 'string' ||
  typeof breakpoints === 'boolean' ||
  Array.isArray(breakpoints)
    ? { [DEFAULT_BREAKPOINT]: breakpoints }
    : breakpoints ?? {};

export const makeClassNamesForBreakpoints = (
  component: LayoutComponent,
  property: string,
  breakpoints?: BreakpointProperty,
) => {
  if (breakpoints === undefined) {
    return [];
  }

  return Object.entries(resolveBreakpointPropertyMap(breakpoints)).reduce<
    string[]
  >((acc, [breakpoint, value]) => {
    if (value === false) {
      return acc;
    }

    const className = makeClassName(
      joinDefined('-', [
        property,
        value === true ? undefined : value,
        breakpoint === DEFAULT_BREAKPOINT ? undefined : breakpoint,
      ]),
      component,
    );

    return [...acc, className];
  }, []);
};

export const resolveBreakpointPropertyValue = <TValue>(
  breakpoint: LayoutBreakpoint | undefined,
  property: BreakpointProperty<TValue>,
): TValue | undefined => {
  const resolvedValues = resolveBreakpointPropertyMap(property);

  if (breakpoint === undefined) {
    return undefined;
  }

  if (
    typeof property === 'object' &&
    property !== null &&
    Object.keys(property).length > 0 &&
    !BREAKPOINTS_HIERARCHY.some(breakpoint => breakpoint in property)
  ) {
    return property as any;
  }

  const breakpointIndex = BREAKPOINTS_HIERARCHY_REVERSE.indexOf(breakpoint);

  return BREAKPOINTS_HIERARCHY_REVERSE.map<TValue>(
    name => resolvedValues[name],
  ).find((value, index) => value !== undefined && index >= breakpointIndex);
};

export const makeHiddenClassNameForBreakpoint = (
  component: LayoutComponent,
  hidden?: BreakpointProperty<boolean>,
  breakpoint?: LayoutBreakpoint,
) => {
  if (breakpoint === undefined || hidden === undefined) {
    return undefined;
  }

  const map = resolveBreakpointPropertyMap(hidden);
  const breakpointIndex = BREAKPOINTS_HIERARCHY_REVERSE.indexOf(breakpoint);
  const resolvedBreakpoint = BREAKPOINTS_HIERARCHY_REVERSE.find((bp, index) => {
    return bp in map && index >= breakpointIndex;
  });

  if (resolvedBreakpoint === undefined) {
    return undefined;
  }

  const value = map[resolvedBreakpoint];

  if (value === false) {
    return undefined;
  }

  return makeClassName('hidden', component);
};
