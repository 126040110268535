import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType, PricesSectionItemData } from '../model';
import { DataHook, DataIndex } from '../types';
import { PricesSectionItemPriceData } from './../model';
import { usePricesSectionItemPriceData } from './use-prices-section-item-price-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    itemsDataIndex: DataIndex<PricesSectionItemPriceData>,
  ): DataIndex<PricesSectionItemData> => {
    const list: PricesSectionItemData[] = graphqlResult.allContentfulPricesSectionItem.nodes.map(
      (node): PricesSectionItemData => ({
        id: node.contentful_id,
        type: DataType.PricesSectionItem,
        data: rejectNil({
          title: node.title,
          serviceType: node.serviceType,
          items: node.items?.map(
            item => itemsDataIndex.record[item.contentful_id],
          ),
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const usePricesSectionItemData: DataHook<PricesSectionItemData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulPricesSectionItem {
        nodes {
          contentful_id
          title
          serviceType
          items {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, usePricesSectionItemPriceData());

  return id === undefined ? index : index.record[id];
};
