import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { CardData, CardGroupData, DataType } from '../model';
import { DataHook, DataIndex } from '../types';
import { useCardData } from './use-card-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    itemsDataIndex: DataIndex<CardData>,
  ): DataIndex<CardGroupData> => {
    const list: CardGroupData[] = graphqlResult.allContentfulCardGroup.nodes.map(
      (node): CardGroupData => ({
        id: node.contentful_id,
        type: DataType.CardGroup,
        data: rejectNil({
          type: node.type,
          title: node.title,
          items: node.items.map(
            item => itemsDataIndex.record[item.contentful_id],
          ),
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useCardGroupData: DataHook<CardGroupData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulCardGroup {
        nodes {
          contentful_id
          type
          items {
            contentful_id
          }
          # TODO when issue related to contentful schema are solved, uncomment
          # title
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useCardData());

  return id === undefined ? index : index.record[id];
};
