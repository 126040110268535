import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import {
  InterleavingImagesSectionData,
  InterleavingImagesSectionItemData,
  ThemeData,
} from '../model';
import { DataType } from '../model/common';
import { DataHook, DataIndex } from '../types';
import { useInterleavingImagesSectionItemData } from './use-interleaving-images-section-item-data';
import { useThemeData } from './use-theme-data';
import { makeDataIndexFromList } from './utils';

// eslint-disable-next-line
const makeIndex = once(
  (
    graphqlResult: any,
    itemsDataIndex: DataIndex<InterleavingImagesSectionItemData>,
    themeDataIndex: DataIndex<ThemeData>,
  ): DataIndex<InterleavingImagesSectionData> => {
    const list: InterleavingImagesSectionData[] = graphqlResult.allContentfulInterleavingImagesSection.nodes.map(
      (node): InterleavingImagesSectionData => ({
        id: node.contentful_id,
        type: DataType.InterleavingImagesSection,
        data: rejectNil({
          items: node.items.map(
            item => itemsDataIndex.record[item.contentful_id],
          ),
          theme:
            node.theme?.contentful_id === undefined
              ? undefined
              : themeDataIndex.record[node.theme.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useInterleavingImagesSectionData: DataHook<InterleavingImagesSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulInterleavingImagesSection {
        nodes {
          contentful_id
          items {
            contentful_id
          }
          theme {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(
    graphqlResult,
    useInterleavingImagesSectionItemData(),
    useThemeData(),
  );

  return id === undefined ? index : index.record[id];
};
