import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType, ThemeData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (graphqlResult: any): DataIndex<ThemeData> => {
    const list: ThemeData[] = graphqlResult.allContentfulTheme.nodes.map(
      (node): ThemeData => ({
        id: node.contentful_id,
        type: DataType.Theme,
        data: rejectNil({
          mode: node.mode,
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useThemeData: DataHook<ThemeData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulTheme {
        nodes {
          contentful_id
          mode
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
