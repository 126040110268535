import { ThemeData } from '../data/model';
import { classNameMap } from './tsx';

export const makeThemeClassName = (
  theme: ThemeData,
  defaultClassNames?: Record<string, boolean> | undefined,
): string =>
  classNameMap({
    ...(defaultClassNames ?? {}),
    ...(theme?.data?.mode ? { [theme.data.mode]: true } : {}),
  });
