import * as path from 'path';

import { StaticPage, StaticPageConfiguration } from '../types/pages';
import { TEMPLATES_DIRECTORY } from './directories';

const resolvePageTemplate = (templatePath: string) =>
  path.resolve(TEMPLATES_DIRECTORY, templatePath);

export const STATIC_PAGES_CONFIGURATION: StaticPageConfiguration[] = [
  {
    contentfulId: '6qwyEf4RseU9OEXj3xzQ0p',
    page: StaticPage.Home,
    template: resolvePageTemplate('page.tsx'),
    type: 'static',
  },
  {
    contentfulId: '3iGPOSgvYEBcdXg6XAfvB8',
    page: StaticPage.About,
    template: resolvePageTemplate('page.tsx'),
    type: 'static',
  },
  {
    contentfulId: '682v9lhpifFcsv99k1Ugic',
    page: StaticPage.Trainings,
    template: resolvePageTemplate('page.tsx'),
    type: 'static',
  },
  {
    contentfulId: '4iQ6wIJGtMbNwcXZ9143R1',
    page: StaticPage.Treatments,
    template: resolvePageTemplate('page.tsx'),
    type: 'static',
  },
  {
    contentfulId: 'qlbNHIR2Ldb7jkEZyXYxr',
    page: StaticPage.Gallery,
    template: resolvePageTemplate('page.tsx'),
    type: 'static',
  },
  {
    contentfulId: '3dvX2rVIjYxNQcyY7dl934',
    page: StaticPage.Laser,
    template: resolvePageTemplate('page.tsx'),
    type: 'static',
  },
  {
    contentfulId: '3Fc6qUK4ed9qILMpR4gYZD',
    page: StaticPage.Prices,
    template: resolvePageTemplate('page.tsx'),
    type: 'static',
  },
  {
    contentfulId: '6afWlRX48eMUiF4a04n9Vx',
    page: StaticPage.Blog,
    template: resolvePageTemplate('blog.tsx'),
    type: 'static',
  },
  {
    contentfulId: '6iqm9pS4lKmt8helCYuXJB',
    page: StaticPage.Contact,
    template: resolvePageTemplate('page.tsx'),
    type: 'static',
  },
  {
    contentfulId: '642RsTxFLcgFVOuTDRQleE',
    page: StaticPage.PrivacyPolicy,
    template: resolvePageTemplate('page.tsx'),
    type: 'static',
  },
  {
    contentfulId: '3W2Q1ecZC95fSJewVgIL22',
    page: StaticPage.Handbook,
    template: resolvePageTemplate('page.tsx'),
    type: 'static',
  },
];
