import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import {
  CollapsibleListData,
  CollapsibleListItemData,
  DataType,
} from '../model';
import { DataHook, DataIndex } from '../types';
import { useCollapsibleListItemData } from './use-collapsible-list-item-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    itemsDataIndex: DataIndex<CollapsibleListItemData>,
  ): DataIndex<CollapsibleListData> => {
    const list: CollapsibleListData[] = graphqlResult.allContentfulCollapsibleList.nodes.map(
      (node): CollapsibleListData => ({
        id: node.contentful_id,
        type: DataType.CollapsibleList,
        data: rejectNil({
          title: node.title,
          items: node.items.map(
            item => itemsDataIndex.record[item.contentful_id],
          ),
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useCollapsibleListData: DataHook<CollapsibleListData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulCollapsibleList {
        nodes {
          contentful_id
          # title
          items {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useCollapsibleListItemData());

  return id === undefined ? index : index.record[id];
};
