import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { ButtonData, DataType, FormData, ImageData, ModalData } from '../model';
import { DataHook, DataIndex } from '../types';
import { useButtonData } from './use-button-data';
import { useFormData } from './use-form-data';
import { useImageData } from './use-image-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    formDataIndex: DataIndex<FormData>,
    buttonDataIndex: DataIndex<ButtonData>,
    imageDataIndex: DataIndex<ImageData>,
  ): DataIndex<ModalData> => {
    const list: ModalData[] = graphqlResult.allContentfulModal.nodes.map(
      (node): ModalData => ({
        id: node.contentful_id,
        type: DataType.Modal,
        data: rejectNil({
          title: node.title,
          subTitle: node.subTitle,
          description: node.description,
          type: node.type,
          image:
            node.image?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.image.contentful_id],
          form:
            node.form?.contentful_id === undefined
              ? undefined
              : formDataIndex.record[node.form.contentful_id],
          button:
            node.button?.contentful_id === undefined
              ? undefined
              : buttonDataIndex.record[node.button.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useModalData: DataHook<ModalData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulModal {
        nodes {
          contentful_id
          title
          description {
            json
          }
          image {
            contentful_id
          }
          form {
            contentful_id
          }
          button {
            contentful_id
          }
          # TODO when issue related to contentful schema are solved, uncomment
          # subTitle
        }
      }
    }
  `);

  const index = makeIndex(
    graphqlResult,
    useFormData(),
    useButtonData(),
    useImageData(),
  );

  return id === undefined ? index : index.record[id];
};
