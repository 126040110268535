import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import {
  DataType,
  GallerySectionItemData,
  GallerySectionItemImageData,
} from '../model';
import { DataHook, DataIndex } from '../types';
import { useGallerySectionItemImageData } from './use-gallery-section-item-image-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    itemsDataIndex: DataIndex<GallerySectionItemImageData>,
  ): DataIndex<GallerySectionItemData> => {
    const list: GallerySectionItemData[] = graphqlResult.allContentfulGallerySectionItem.nodes.map(
      (node): GallerySectionItemData => ({
        id: node.contentful_id,
        type: DataType.GallerySectionItem,
        data: rejectNil({
          title: node.title,
          description: node.description,
          items: node.items.map(
            item => itemsDataIndex.record[item.contentful_id],
          ),
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useGallerySectionItemData: DataHook<GallerySectionItemData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulGallerySectionItem {
        nodes {
          contentful_id
          title
          items {
            contentful_id
          }
          # TODO when issue related to contentful schema are solved, uncomment
          # description
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useGallerySectionItemImageData());

  return id === undefined ? index : index.record[id];
};
