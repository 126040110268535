import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType, ImageData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (graphqlResult: any): DataIndex<ImageData> => {
    const list: ImageData[] = graphqlResult.allContentfulImage.nodes.map(
      (node): ImageData => ({
        id: node.contentful_id,
        type: DataType.Image,
        data: rejectNil({
          alt: node.alt,
          bitmap: {
            url: node.bitmap?.file?.url,
            fluid: node.bitmap?.fluid,
          },
          vector: {
            url: node?.vector?.file?.url,
          },
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useImageData: DataHook<ImageData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulImage {
        nodes {
          contentful_id
          alt
          vector {
            file {
              url
            }
          }
          bitmap {
            file {
              url
            }
            fluid {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
