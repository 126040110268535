import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { ConfigData, DataType, ImageData } from '../model';
import { DataHook, DataIndex } from '../types';
import { useImageData } from './use-image-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    imageDataIndex: DataIndex<ImageData>,
  ): DataIndex<ConfigData> => {
    const list: ConfigData[] = graphqlResult.allContentfulConfig.nodes.map(
      (node): ConfigData => ({
        id: node.contentful_id,
        type: DataType.Config,
        data: rejectNil({
          email: node.email,
          formattedPhoneNumber: node.formattedPhoneNumber,
          formattedAddress: node.formattedAddress,
          phoneNumber: node.phoneNumber,
          logoLight:
            node.logoLight?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.logoLight.contentful_id],
          logoDark:
            node.logoDark?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.logoDark.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useConfigData: DataHook<ConfigData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulConfig {
        nodes {
          contentful_id
          email
          formattedPhoneNumber
          formattedAddress
          phoneNumber
          logoLight {
            contentful_id
          }
          logoDark {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
