import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { DataType, TestimonialData, TestimonialsData } from '../model';
import { DataHook, DataIndex } from '../types';
import { useTestimonialData } from './use-testimonial-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    testimonialsIndex: DataIndex<TestimonialData>,
  ): DataIndex<TestimonialsData> => {
    const list: TestimonialsData[] = graphqlResult.allContentfulTestimonials.nodes.map(
      (node): TestimonialsData => ({
        id: node.contentful_id,
        type: DataType.Testimonials,
        data: rejectNil({
          testimonials: node.testimonials.map(
            testimonial => testimonialsIndex.record[testimonial.contentful_id],
          ),
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useTestimonialsData: DataHook<TestimonialsData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulTestimonials {
        nodes {
          contentful_id
          testimonials {
            contentful_id
          }
        }
      }
    }
  `);

  const testimonialsIndex = useTestimonialData();
  const index = makeIndex(graphqlResult, testimonialsIndex);

  return id === undefined ? index : index.record[id];
};
