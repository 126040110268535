export const once = <TFunc extends (...args: any[]) => any>(
  func: TFunc,
): TFunc => {
  let called = false;
  let result: ReturnType<TFunc>;

  return ((...args) => {
    if (called === false) {
      called = true;
      result = func(...args);
    }

    return result;
  }) as TFunc;
};
