import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import {
  AboutSectionItemData,
  ButtonData,
  DataType,
  ImageData,
} from '../model';
import { DataHook, DataIndex } from '../types';
import { useButtonData } from './use-button-data';
import { useImageData } from './use-image-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    buttonDataIndex: DataIndex<ButtonData>,
    imageDataIndex: DataIndex<ImageData>,
  ): DataIndex<AboutSectionItemData> => {
    const list: AboutSectionItemData[] = graphqlResult.allContentfulAboutSectionItem.nodes.map(
      (node): AboutSectionItemData => ({
        id: node.contentful_id,
        type: DataType.AboutSectionItem,
        data: rejectNil({
          name: node.name,
          position: node.position,
          image:
            node.image?.contentful_id === undefined
              ? undefined
              : imageDataIndex.record[node.image.contentful_id],
          button:
            node.button?.contentful_id === undefined
              ? undefined
              : buttonDataIndex.record[node.button.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useAboutSectionItemData: DataHook<AboutSectionItemData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulAboutSectionItem {
        nodes {
          contentful_id
          image {
            contentful_id
          }
          button {
            contentful_id
          }
          name
          position
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useButtonData(), useImageData());

  return id === undefined ? index : index.record[id];
};
