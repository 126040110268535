import { graphql, useStaticQuery } from 'gatsby';

import { BlogPostPartial } from '../../types';
import { once } from '../../utils';
import { DataType } from '../model';

const makeList = once((graphqlResult: any): BlogPostPartial[] => {
  const list = graphqlResult.allContentfulBlogPostPage.nodes
    .map(
      (node): BlogPostPartial => ({
        seo: {
          id: node.seo.contentful_id,
          type: DataType.PageSeo,
          data: node.seo,
        },
        date: node.date,
        excerpt: node.excerpt,
        slug: node.slug,
        title: node.title,
        image: {
          id: node.image.contentful_id,
          type: DataType.Image,
        },
      }),
    )
    .sort((a, b) => {
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);

      return bDate.valueOf() - aDate.valueOf();
    });

  return list;
});

export const useBlogPostsPartialsData = (): BlogPostPartial[] => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulBlogPostPage {
        nodes {
          contentful_id
          title
          date
          content {
            json
          }
          excerpt {
            json
          }
          seo {
            contentful_id
            description
            title
          }
          slug
          image {
            contentful_id
          }
        }
      }
    }
  `);

  return makeList(graphqlResult);
};
