import { useEffect, useState } from 'react';

import {
  BREAKPOINTS,
  BREAKPOINTS_HIERARCHY,
  BREAKPOINTS_HIERARCHY_REVERSE,
} from '../config';
import { LayoutBreakpoint } from '../types';

type BreakpointMap = Partial<Record<LayoutBreakpoint, string>>;
type ScreenMap = Partial<Record<LayoutBreakpoint, boolean>>;

const queries = BREAKPOINTS_HIERARCHY.reduce<BreakpointMap>((acc, name) => {
  const breakpoint = BREAKPOINTS[name];

  return {
    ...acc,
    [name]: `(min-width: ${breakpoint}px)`,
  };
}, {});

type SubscribeFunc = (screens: ScreenMap) => void;
const subscribers = new Map<number, SubscribeFunc>();
let subUid = -1;
let screens = {};

const ResponsiveObserve = {
  matchHandlers: {} as {
    [prop: string]: {
      mql: MediaQueryList;
      listener: ((this: MediaQueryList, ev: MediaQueryListEvent) => any) | null;
    };
  },
  dispatch(pointMap: ScreenMap) {
    screens = pointMap;
    subscribers.forEach(func => func(screens));
    return subscribers.size >= 1;
  },
  subscribe(func: SubscribeFunc): number {
    if (!subscribers.size) this.register();
    subUid += 1;
    subscribers.set(subUid, func);
    func(screens);
    return subUid;
  },
  unsubscribe(token: number) {
    subscribers.delete(token);
    if (!subscribers.size) this.unregister();
  },
  unregister() {
    Object.keys(queries).forEach((breakpoint: LayoutBreakpoint) => {
      const matchMediaQuery = queries[breakpoint];
      const handler = this.matchHandlers[matchMediaQuery];
      handler?.mql.removeListener(handler?.listener);
    });
    subscribers.clear();
  },
  register() {
    Object.keys(queries).forEach((breakpoint: LayoutBreakpoint) => {
      const matchMediaQuery = queries[breakpoint];
      const listener = ({ matches }: { matches: boolean }) => {
        this.dispatch({
          ...screens,
          [breakpoint]: matches,
        });
      };
      const mql = window.matchMedia(matchMediaQuery);

      if (typeof mql?.addEventListener === 'function') {
        mql.addEventListener('change', listener);
      }

      if (typeof mql?.addListener === 'function') {
        mql.addListener(listener);
      }

      this.matchHandlers[matchMediaQuery] = {
        mql,
        listener,
      };

      listener(mql);
    });
  },
};

const useScreens = (): [Array<LayoutBreakpoint>, Set<LayoutBreakpoint>] => {
  const [screens, setScreens] = useState<
    [Array<LayoutBreakpoint>, Set<LayoutBreakpoint>]
  >([[], new Set()]);

  useEffect(() => {
    const token = ResponsiveObserve.subscribe(supportScreens => {
      const resolvedScreens = new Set<LayoutBreakpoint>();

      for (const breakpoint of BREAKPOINTS_HIERARCHY_REVERSE) {
        if (supportScreens[breakpoint] === true) {
          resolvedScreens.add(breakpoint);
        }
      }

      setScreens([Array.from(resolvedScreens), resolvedScreens]);
    });

    return () => ResponsiveObserve.unsubscribe(token);
  }, []);

  return screens;
};

export default useScreens;
