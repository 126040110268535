import { STATIC_PAGES_CONFIGURATION } from '../config/pages';
import { usePageData } from '../data';
import { PageData } from '../data/model/page';
import { DataIndex } from '../data/types';
import { StaticPage } from '../types/pages';
import { once } from '../utils/fp';

const STATIC_PAGES_DATA_HOOKS = [usePageData];

const makePagesDataIndex = once((indexes: Array<DataIndex<PageData>>) => {
  const combinedIndexes = indexes.reduce<Record<string, PageData>>(
    (acc, index) => ({ ...acc, ...index.record }),
    {},
  );

  return STATIC_PAGES_CONFIGURATION.reduce<Record<StaticPage, PageData>>(
    (acc, config) => ({
      ...acc,
      [config.page]: combinedIndexes[config.contentfulId],
    }),
    {} as any,
  );
});

export const useStaticPages = () => {
  const data = makePagesDataIndex(STATIC_PAGES_DATA_HOOKS.map(hook => hook()));

  return data;
};
