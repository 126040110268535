import { graphql, useStaticQuery } from 'gatsby';

import { once, rejectNil } from '../../utils';
import { ButtonData, CtaSectionData, DataType, ThemeData } from '../model';
import { DataHook, DataIndex } from '../types';
import { useButtonData } from './use-button-data';
import { useThemeData } from './use-theme-data';
import { makeDataIndexFromList } from './utils';

const makeIndex = once(
  (
    graphqlResult: any,
    buttonDataIndex: DataIndex<ButtonData>,
    themeDataIndex: DataIndex<ThemeData>,
  ): DataIndex<CtaSectionData> => {
    const list: CtaSectionData[] = graphqlResult.allContentfulCtaSection.nodes.map(
      (node): CtaSectionData => ({
        id: node.contentful_id,
        type: DataType.CtaSection,
        data: rejectNil({
          title: node.title,
          description: node.description,
          theme:
            node.theme?.contentful_id === undefined
              ? undefined
              : themeDataIndex.record[node.theme.contentful_id],
          button:
            node.button?.contentful_id === undefined
              ? undefined
              : buttonDataIndex?.record[node.button.contentful_id],
        }),
      }),
    );

    return makeDataIndexFromList(list);
  },
);

export const useCtaSectionData: DataHook<CtaSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulCtaSection {
        nodes {
          contentful_id
          title
          description {
            json
          }
          button {
            contentful_id
          }
          # TODO when issue related to contentful schema are solved, uncomment
          # theme {
          #   contentful_id
          # }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useButtonData(), useThemeData());

  return id === undefined ? index : index.record[id];
};
